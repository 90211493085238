const Targets = document.querySelectorAll('.parallax');

if (Targets.length > 0) {
    addEventListener('scroll', () => {
        for (let index = 0; index < Targets.length; index++) {
            let pos = window.scrollY * Targets[index].dataset.rate;

            if (Targets[index].dataset.direction === 'vertical') {
                Targets[index].style.transform = 'translate3d(0px,' + pos + 'px, 0px)';
            } else {
                let posX = window.scrollY * Targets[index].dataset.ratex;
                let posY = window.scrollY * Targets[index].dataset.ratey;

                Targets[index].style.transform = 'translate3d(' + posX + 'px,' + posY + 'px, 0px)';
            }
        }
    }, { passive: true })
}


