// Valori di configurazione predefiniti
const DEFAULT_CONFIG = {
  transitionDuration: 400,  // Durata dell'animazione di transizione dell'opacità e del filtro
  wordChangeInterval: 2500, // Intervallo tra un cambio di parola e l'altro
  words: [
    'personalizzato',
    'sicuro',
    'responsive',
    'veloce',
    'accessibile',
    'affidabile',
    'interattivo',
    'ottimizzato',
  ],
};

// Recupera il div contenente il testo con gradiente
const gradientText = document.querySelector('#hero-gradient-text');

// Funzione ricorsiva per cambiare la parola visualizzata
function changeWord(index, config) {

  if (gradientText) {
    // Imposta le impostazioni di configurazione con i valori predefiniti se non sono state specificate
    config = { ...DEFAULT_CONFIG, ...config };

    // Imposta l'opacità e il filtro su 0 e riduce leggermente la dimensione del testo
    gradientText.style.opacity = 0;
    gradientText.style.filter = 0;
    gradientText.style.transform = 'scale(98%)';

    // Dopo transitionDuration ms, cambia la parola visualizzata e ripristina l'opacità e la dimensione del testo
    setTimeout(() => {
      gradientText.textContent = config.words[index];
      gradientText.style.opacity = 1;
      gradientText.style.transform = 'scale(100%)';

      // Dopo wordChangeInterval ms, richiama la funzione per cambiare la parola di nuovo
      setTimeout(() => {
        changeWord((index + 1) % config.words.length, config);
      }, config.wordChangeInterval);
    }, config.transitionDuration);
  }
}

// Inizialmente, visualizza la prima parola della matrice con le impostazioni di configurazione predefinite

changeWord(0, {});