const stackLogos = document.querySelectorAll('.stack-logo');

if (stackLogos) {

    let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.2,
        itemDelay: 400,
    };

    function delayed(delay) {
        return new Promise(resolve => setTimeout(resolve, delay));
    }

    let stackLogosObserver = new IntersectionObserver(stackLogos => {
        stackLogos.forEach((entry, i) => {
            let delay = options.itemDelay * i;
            if (entry.isIntersecting) {
                if (entry == stackLogos[2]) {
                    entry.target.classList.remove('dark:fill-white')
                }
                entry.target.setAttribute('style', 'filter: grayscale(0%) !important');
                entry.target.style.transitionDelay = delay + "ms";
                return delayed(800 + delay).then(function () {
                    entry.target.setAttribute('style', 'filter: grayscale(100%)');
                    entry.target.style.transitionDelay = '0ms';
                    if (entry.target == stackLogos[2].target) {
                        entry.target.classList.add('dark:fill-white')
                    }
                });
            }
        });
    }, options);

    stackLogos.forEach(element => stackLogosObserver.observe(element));
}