let elements = document.querySelectorAll('.fade-in');
elements.forEach(element => {
  element.style.transition = '1s'
  element.style.transform = `scale(0.97)`;
  element.style.opacity = 0;
});



let options = {
  root: null,
  rootMargin: "0px",
  threshold: 0.1,
  itemDelay: 200,
};

let callback = entries => entries.forEach((entry, i) => {
  if (entry.isIntersecting) {
    entry.target.style.transform = `scale(1)`;
    entry.target.style.opacity = 1;
    entry.target.style.transitionDelay = options.itemDelay * i + "ms";
    entry.target.classList.remove('fade-in');
  }
});

let observer = new IntersectionObserver(callback, options);

elements.forEach(element => observer.observe(element));



