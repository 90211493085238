let respLottie = document.querySelector('#responsive-lottie');
let respLottieWrapper = document.querySelector('#responsive-lottie-wrapper');
let respContentWrapper = document.querySelector('#responsive-content-wrapper');

if (respLottie && respLottieWrapper && respContentWrapper) {
    function resizeWrapper() {
        respLottieWrapper.setAttribute('style', `height: ${respContentWrapper.offsetHeight}px`);
        respLottie.style.height = respContentWrapper.offsetHeight + 'px';
    }

    addEventListener('load', resizeWrapper, { passive: true })
    addEventListener('resize', resizeWrapper, { passive: true });
}