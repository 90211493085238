// HeightMatch.js

/**
 * Throttles a function call to limit the frequency of execution.
 */
function throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function () {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                if ((Date.now() - lastRan) >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
}

/**
 * Calculates the total height of an element.
 */
function getTotalHeight(el) {
    const style = window.getComputedStyle(el);
    return el.clientHeight;
}

/**
 * Matches the heights of elements based on data attributes if the screen width exceeds a certain value.
 */
function matchHeights(minWidth = 768) {
    if (window.innerWidth < minWidth) {
        return;
    }

    const elements = document.querySelectorAll('[data-match-height-to]');

    elements.forEach(el => {
        const matchValue = el.getAttribute('data-match-height-to');
        const targetElement = document.querySelector(`[data-match-height-ref="${matchValue}"]`);

        if (targetElement) {
            if (el.offsetParent !== null && targetElement.offsetParent !== null) {
                setTimeout(() => {
                    const height = getTotalHeight(targetElement);
                    el.style.height = `${height}px`;
                }, 250);
            }
        }
    });
}

function setupMatchHeights(minWidth = 768) {
    // Verifica la larghezza dello schermo prima di eseguire l'abbinamento delle altezze
    if (window.innerWidth < minWidth) {
        return;
    }

    // Esegui il match delle altezze subito e ogni volta che la finestra viene ridimensionata
    matchHeights();
    window.addEventListener('resize', throttle(matchHeights, 250));
}

// Esporta le funzioni per l'utilizzo esterno
export { setupMatchHeights, matchHeights };
