import Swal from 'sweetalert2'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    showCloseButton: false,
    timerProgressBar: true,
    timer: 5000,
    width: 'max-content',
    customClass: {
        container: '...',
        popup: '...',
        header: '...',
        title: 'mb-0',
        closeButton: '...',
        icon: '...',
        image: '...',
        htmlContainer: 'mt-0 text-sm',
        input: '...',
        inputLabel: '...',
        validationMessage: '...',
        actions: '...',
        confirmButton: '...',
        denyButton: '...',
        cancelButton: '...',
        loader: '...',
        footer: '....',
        timerProgressBar: 'bg-green-600',
    },
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer, { passive: true })
        toast.addEventListener('mouseleave', Swal.resumeTimer, { passive: true })
    }
});

window.addEventListener('alert', ({
    detail: {
        type,
        message,
        text
    }
}) => {
    Toast.fire({
        icon: type,
        iconColor: type == 'success' ? 'green' : 'red',
        title: message,
        text: text,
    })
}, { passive: true })