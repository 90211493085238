class Circle {
    height: number;
    width: number;
    colors: string | string[];

    constructor(minSize: number, maxSize: number, colors?: string[]) {
        // Generate a random height and assign to this.height
        this.height = Math.floor(Math.random() * (maxSize - minSize + 1) + minSize);
        this.width = this.height;
        // If colors is not passed, assign a random color to this.colors 
        if (!colors) {
            this.colors = '#' + Math.floor(Math.random() * 16777215).toString(16);
        } else {
            // Throw an error if colors is not an array of strings
            if (!Array.isArray(colors)) {
                throw new TypeError('Colors should be an array of strings');
            }
            this.colors = colors;
        }
    }
}

// Define constant variables
const canvas = document.getElementById('bg-circles') as HTMLElement;
const spawnInterval = 400; //milliseconds
const fadeInTime = 1000; //milliseconds
const fadeOutTime = 1000; //milliseconds
const minSize = window.innerWidth < 768 ? 500 : 1000; //pixels
const maxSize = window.innerWidth < 768 ? 800 : 1600; //pixels
const colors = ['#00eeff', '#5100ff', '#ffa200']; //array of hex color strings or undefined
const verticalSpawnMargin = 0; //0-50 (50 is the middle of the vertical space)
const horizontalSpawnMargin = 0; //0-50 (50 is the middle of the horizontal space)
const targetOpacity = 0.2; //0-1
const blurAmount = 50; //pixels
const movementXRange = 800; //pixels
const movementYRange = 800; //pixels
const movementDelay = 800; //milliseconds
const movementSpeed = 4000; //milliseconds

// Declare variables

if (canvas) {
    const lifetime = fadeInTime + fadeOutTime + movementDelay;
    let canvasHeight = +getComputedStyle(canvas).height.slice(0, -2);
    let canvasWidth = +getComputedStyle(canvas).width.slice(0, -2);
    let verticalMargin;
    let horizontalMargin;
    let circleIndex = 0;
    let colorsIndex = 0;

    // Set the margins based on the canvas size
    function setMargins(vertical: number, horizontal: number) {
        vertical = (vertical / 100) * canvasHeight;
        horizontal = (horizontal / 100) * canvasWidth;
        verticalMargin = Math.min(vertical, canvasHeight / 2);
        horizontalMargin = Math.min(horizontal, canvasWidth / 2);
    }

    setMargins(verticalSpawnMargin, horizontalSpawnMargin);
    // Create a circle
    createCircle();

    let spawnCircleInterval;
    // // Create an intersection observer for the canvas
    // let canvasObserver = new IntersectionObserver((entries) => {
    //     entries.forEach((entry) => {
    //         // If the canvas is intersecting, start a spawn interval
    //         if (entry.isIntersecting) {

                spawnCircleInterval = setInterval(() => {
                    createCircle();
                }, spawnInterval);

    //             // Otherwise, clear the interval
    //         } else {
    //             clearInterval(spawnCircleInterval);
    //         }
    //     });
    // }, { rootMargin: '0px', threshold: 0.2 });

    // canvasObserver.observe(canvas);

    // Generate a random position between a given min and max
    function getRandomPosition(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1) + min) + 'px';
    }

    // Create a circle
    function createCircle() {
        const canvasHeight = +getComputedStyle(canvas).height.slice(0, -2);
        const canvasWidth = +getComputedStyle(canvas).width.slice(0, -2);
        const newCircle = new Circle(minSize, maxSize, colors);
        const circleDiv = document.createElement('div');
        // Assign an id to the circleDiv
        circleDiv.id = 'circle_' + circleIndex++;
        // Add a class to the circleDiv
        circleDiv.classList.add('hero-circle');

        // If newCircle.colors is a string, assign it to circleDiv.style.backgroundColor
        if (!Array.isArray(newCircle.colors)) {
            circleDiv.style.backgroundColor = newCircle.colors;
            // Otherwise, assign the color from newCircle.colors at the current index
        } else {
            circleDiv.style.backgroundColor = newCircle.colors[colorsIndex];
            // Increment the index
            colorsIndex++;
            // If the index is greater than the length of the newCircle.colors array, reset the index
            if (colorsIndex > newCircle.colors.length) {
                colorsIndex = 0;
            }
        }

        // Assign the height and width of the circleDiv
        circleDiv.style.height = newCircle.height + 'px';
        circleDiv.style.width = newCircle.width + 'px';
        // Set the position, border radius and transition
        circleDiv.style.position = 'absolute';
        circleDiv.style.top = getRandomPosition(verticalMargin, canvasHeight - verticalMargin);
        circleDiv.style.left = getRandomPosition(horizontalMargin, canvasWidth - horizontalMargin);
        circleDiv.style.borderRadius = '100%';
        circleDiv.style.transition = `opacity ${fadeInTime}ms ease-in-out, transform ${movementSpeed}ms ease-in-out`;
        // Set the blur and transform
        circleDiv.style.filter = `blur(${blurAmount}px)`;
        circleDiv.style.transform = 'translate(-50%, -50%)';
        // Assign an initial opacity
        circleDiv.style.opacity = '0';

        // Append the circleDiv to the canvas
        canvas.appendChild(circleDiv);

        // After a set timeout, assign the targetOpacity to circleDiv.style.opacity
        setTimeout(() => {
            circleDiv.style.opacity = opacityAdjustForDarkBg(targetOpacity).toString();
            // After a set timeout, assign a random translate to circleDiv.style.transform
            setTimeout(() => {
                circleDiv.style.transform = `translate(calc(-50% + ${getRandomPosition(-movementXRange, movementXRange)}), calc(-50% + ${getRandomPosition(-movementYRange, movementYRange)}))`;
                // After a set timeout, set circleDiv.style.opacity to 0
                setTimeout(() => {
                    circleDiv.style.opacity = '0';
                    // After a set timeout, remove the circleDiv from the canvas
                    setTimeout(() => {
                        canvas.removeChild(circleDiv);
                    }, lifetime);
                }, fadeOutTime);
            }, movementDelay);
        }, fadeOutTime);
    }

    function opacityAdjustForDarkBg(targetOpacity) {
        if (localStorage.getItem('colorTheme') == 'dark') {
            return targetOpacity / 2;
        } else {
            return targetOpacity;
        }
    }
}