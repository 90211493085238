import { create } from '@lottiefiles/lottie-interactivity'

const helloLottiePlayer = document.querySelector('#hello-lottie');

if (helloLottiePlayer) {
    helloLottiePlayer.addEventListener('ready', () => {
        create({
            player: helloLottiePlayer.getLottie(),
            mode: 'scroll',
            actions: [
                {
                    container: "#about-me",
                    visibility: [0, 1],
                    type: 'playOnce',
                    frames: [0, 300],
                }
            ],
        });
    }, { passive: true });
}

const responsiveLottiePlayer = document.querySelector('#responsive-lottie');

if (responsiveLottiePlayer) {
    responsiveLottiePlayer.addEventListener('ready', () => {
        create({
            player: responsiveLottiePlayer.getLottie(),
            mode: 'scroll',
            actions: [
                {
                    visibility: [0.1, 1.0],
                    type: 'playOnce',
                    frames: [0, 900],
                }
            ],
        });
    }, { passive: true });
}