let darkModeSwitchMobile = document.querySelector('#dark-mode-toggle');
let darkModeSwitchDesktop = document.querySelector('#dark-mode-toggle-desktop');
let colorTheme = localStorage.getItem('colorTheme');

if (colorTheme === 'dark' || (!colorTheme && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark');
    darkModeSwitchMobile.checked = true;
    darkModeSwitchDesktop.checked = true;
} else {
    document.documentElement.classList.remove('dark');
    darkModeSwitchMobile.checked = false;
    darkModeSwitchDesktop.checked = false;
}

function setTheme(darkModeSwitch) {
    let colorTheme = (darkModeSwitch.checked == true) ? 'dark' : 'light';
    document.documentElement.classList.toggle('dark', colorTheme === 'dark');
    localStorage.setItem('colorTheme', colorTheme);
}

darkModeSwitchMobile.addEventListener('change', () => setTheme(darkModeSwitchMobile), {passive: true});
darkModeSwitchDesktop.addEventListener('change', () => setTheme(darkModeSwitchDesktop), {passive: true});
