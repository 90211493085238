import './bootstrap';
import './hero-circles-bg'
import './hero-gradient-text';
import './aos'
import './fixed-cta-toggler';
import './parallax'
import '@dotlottie/player-component';
import './swal2';
import './dark-mode';
import './fade-in';
import './lotties-interactivity';
import './stacks-logos-color-peek';
import './lotties-height-adjustment';
import './script';

import { setupMatchHeights } from './HeightMatch'; // Importa la funzione setupMatchHeights

// Configura la larghezza minima dello schermo desiderata
document.addEventListener('DOMContentLoaded', () => {
  setupMatchHeights(1024);
});

// import './about-me-animations';